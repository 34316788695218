/**
 * Created by plenar on 23/12/2015.
 */

angular.module('app.routes').config(routes);

function routes($stateProvider, $urlRouterProvider, $locationProvider) {

    $urlRouterProvider.otherwise("dashboard");

    $stateProvider
        .state('dashboard', {
            url: "/dashboard",
            templateUrl: "/main_dashboard",
            controller: "DashboardController",
            controllerAs: "dashboard"
        })
        .state('profile', {
            url: "/profile",
            templateUrl: "/loadProfile",
            controller: "ProfileController",
            controllerAs: "profile"
        })
        .state('emergency', {
            url: "/emergency",
            templateUrl: "/loadEmergency",
            controller: 'EmergencyController',
            controllerAs: 'emergency'
        })
        .state('check-in', {
            url: "/check-in",
            templateUrl: "/loadCheckin",
            controller: 'CheckinController',
            controllerAs: 'checkin'
        })
        .state('alarm', {
            url: "/alarm",
            templateUrl: "/loadAlarm",
            controller: 'AlarmController',
            controllerAs: 'alarm'
        })
        .state('history', {
            url: "/history",
            templateUrl: "/loadHistory",
            controller: 'HistoryController',
            controllerAs: 'history'
        });
    //$locationProvider.html5Mode(true);
}
angular.module('app.core').controller('DashboardController', ['$state', '$rootScope', 'ShowService', function($state, $rootScope, ShowService) {
    var vm = this;
    vm.user_address = {};
    vm.userId = null;
    vm.latitude = '';
    vm.longitude = '';
    $rootScope.userName = '';
    $rootScope.products = {};

    vm.getAddress = function() {
        ShowService.get('api/v1/user/address', vm.userId).then(function(results) {
            vm.latitude = results.response.user.user_address.latitude;
            vm.longitude = results.response.user.user_address.longitude;
            $rootScope.userName = results.response.user.name;
            $rootScope.products = results.response.products;
        });
    }

    vm.setPath = function(alarmAdded) {
        ShowService.query('api/v1/checkin/count').then(function(result) {
            path = ( result.checkins_count == 0 ) ? 'dashboard' : 'alarm';
            $state.go(path);
        });
    }

    $rootScope.$on('$stateChangeSuccess', function() {
       document.body.scrollTop = document.documentElement.scrollTop = 0;
    });
}]);
