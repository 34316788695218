angular.module('app.core').controller('AlarmController', ['$scope', '$filter', '$state', '$timeout', 'ShowService', 'StoreService', 'moment', 'socket', '$rootScope', function($scope, $filter, $state, $timeout, ShowService, StoreService, moment, socket, $rootScope) {

    var vm = this;
    vm.alarms = {};
    vm.escalated_alarms = {};
    vm.all_alarms = {};
    vm.cancel = {};

    vm.showFireAlarmDiv = false;
    vm.startCount = 15;
    vm.endCount = 0;
    vm.user_pin = '';
    vm.showWrongPinErrorDiv = false;
    vm.showWrongPinErrorDivFor = 0;
    vm.subscription_expired = true;
    vm.disableEscalateButton = false;
    vm.mapCenterLatLag = {latitude: null, longitude: null};

    $rootScope.userName = '';

    vm.setPath = function(alarmAdded) {
        ShowService.query('api/v1/checkin/count').then(function(result) {
            path = ( result.checkins_count == 0 ) ? 'dashboard' : 'alarm';
            $state.go(path);
        });
    }

    vm.showFireAlarm = function() {
        vm.showFireAlarmDiv = true;
        startCounDown = $timeout(function() {
            vm.startCount--;
            vm.endCount++;

            if (vm.endCount === 15) {
                vm.fireAlarm();
            } else {
                vm.showFireAlarm();
            }
        }, 1000);
    }

    vm.hideFireAlarm = function() {
        $timeout.cancel(startCounDown);
        vm.showFireAlarmDiv = false;
        vm.showWrongPinErrorDiv = false;
        vm.startCount = 15;
        vm.endCount = 0;
        vm.cancel.pin = '';
    }

    vm.fireAlarm = function() {
        vm.disableEscalateButton = true;
        id = vm.alarms[0]['id'];
        StoreService.update('api/v1/checkin/escalate', id).then(function(result) {
            vm.loadAlarms();
            vm.disableEscalateButton = false;
        }, function(error) {
            console.log(error);
        });

        vm.hideFireAlarm();
    }

    vm.checkPin = function(pin) {
        return pin == vm.user_pin;
    }

    vm.cancelAlarm = function(pin) {
        if (vm.checkPin(pin)) {
            vm.hideFireAlarm();
        } else {
            vm.showWrongPinErrorDiv = true;
        }
    }

    vm.loadAlarms = function() {
        ShowService.query('api/v1/checkin').then(function(result) {
            vm.alarms = result.response;
            vm.user_pin = result.pin;
            vm.subscription_expired = result.subscription_expired;
            $rootScope.userName = result.userName;

            if(!vm.subscription_expired) {
                /* Modal Backdrop Issue*/
                $('.modal').modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            }

            $rootScope.$broadcast('alarmsLoaded', { message: 'alarmsLoaded...' }, function() {
                console.log('alarmsLoaded fired');
            });

            vm.showOverlapError    = false;
            vm.disableSnoozeButton = false;
            vm.mapCenterLatLag     = {latitude: vm.alarms[0].latitude, longitude: vm.alarms[0].longitude};

        }, function(error) {
            console.log(error);
        });
    }

    vm.showModal = function(id) {
        vm.showWrongPinErrorDiv = false;
        $("#alarmModal" + id).find('input').val('');
        $("#alarmModal" + id).modal('show');
    }

    vm.snoozeAlarm = function(id, pin) {
        if (vm.checkPin(pin)) {
            StoreService.update('api/v1/checkin', id).then(function(result) {
                if (result.error == false) {
                    vm.loadAlarms();
                    vm.showWrongPinErrorDiv = false;
                    $("#alarmModal" + id).modal('hide');
                } else {
                    if(result.response.message == 'overlaps') {
                        console.log('djkdaghal');
                        vm.showOverlapErrorFor = id;
                        vm.showOverlapError    = true;
                        vm.disableSnoozeButton = true;
                        vm.showWrongPinErrorDiv = false;
                    }
                }
            }, function(error) {
                console.log(error);
            });
        } else {
            vm.showWrongPinErrorDiv = true;
            vm.showWrongPinErrorDivFor = id;
        }
    }

    vm.clearAlarm = function(id, pin, alarm) {
        if (vm.checkPin(pin)) {
            ShowService.remove('api/v1/checkin', id).then(function(result) {
                vm.showWrongPinErrorDiv = false;
                $("#alarmModal" + id).modal('hide');
                vm.loadAlarms();
            }, function(error) {
                console.log(error);
            });
        } else {
            vm.showWrongPinErrorDiv = true;
            vm.showWrongPinErrorDivFor = id;
        }
    }

    vm.isPostTime = function(date, time) {
        vm.clock = moment();
        var meeting_time = moment(date + ' ' + time);
        var diff = meeting_time.diff(vm.clock, 'minutes');

        if(diff < 0) {
            return true;
        }
        else {
            return false;
        }
    }

    var isDraggable = $(document).width() > 480 ? true : false; // If document (your website) is wider than 480px, isDraggable = true, else isDraggable = false

    vm.mapOptions = {
        draggable: $(document).width() > 480 ? true : false,
        scrollwheel: false, // Prevent users to start zooming the map when scrolling down the page
    };

    //vm.mapOptions = {scrollwheel: false};

    socket.on("user_alarm_action_fired_" + $('#auth_id').val() + ":App\\Events\\User\\AlarmActionFired", function(message){
        vm.loadAlarms();
    });
}]);
