/**
 * Profile Controller for edit profile
 */
angular.module('app.core').controller('ProfileController', ['ShowService', 'StoreService', '$scope', '$rootScope', 'socket', function(ShowService, StoreService, $scope, $rootScope, socket) {

    var vm = this;

    vm.userId = '';
    vm.userName = '';
    vm.userPhone = '';

    vm.profile_data = '';
    vm.user_address = '';
    vm.userFormattedAddress = '';

    //vm.phone = '';
    vm.password = '';
    vm.pin = '';

    vm.nameValidation = false;
    vm.nameValidationMsg = null;
    vm.nameStatus = null;

    vm.phoneValidation = false;
    vm.phoneValidationMsg = null;
    vm.phoneStatus = null;

    vm.passwordValidation = false;
    vm.passwordValidationMsg = null;
    vm.passwordStatus = null;

    vm.pinValidation = false;
    vm.pinValidationMsg = null;
    vm.pinStatus = null;

    vm.addressValidation = false;
    vm.addressValidationMsg = null;
    vm.addressStatus = null;

    $rootScope.products = {};

    vm.waitingForConfirmation = false;

    vm.isPhoneDirty = function() {
        return vm.userPhone != vm.phone.phone;
    }

    vm.getProfile = function() {
        ShowService.get('api/v1/profile', vm.userId).then(function(results) {
            vm.profile_data = results.response.user;

            vm.user_address = results.response.user.user_address;
            vm.userName = results.response.user.name;
            vm.userPhone = results.response.user.phone;
            vm.userFormattedAddress = results.response.user.user_address.formatted_address;
            $rootScope.products = results.response.products;

            if (results.response.user.phone2 && !results.response.user.phone2_verified) {
                vm.waitingForConfirmation = true;
                vm.userPhone = results.response.user.phone2;
            }
        });
    }

    vm.showphone = function() {
        vm.myphone = true;
        //vm.phone.phone = "";
        vm.phone.phone = vm.userPhone;
        //$("#myInfophone").val('');
        vm.phoneValidation = false;
        vm.phoneStatus = '';
        $scope.phoneForm.$setUntouched();
    };

    vm.hidephone = function() {
        vm.myphone = false;
    };

    vm.showpassword = function() {
        vm.mypassword = true;
        vm.password.old_password = '';
        vm.password.password = '';
        vm.passwordValidation = false;
        vm.passwordStatus = '';
        $scope.passwordForm.$setUntouched();
    };
    vm.hidepassword = function() {
        vm.mypassword = false;
    };

    vm.showpin = function() {
        vm.mypin = true;
        vm.pin.pin = "";
        vm.pin.pin_confirmation = "";
        $("#myInfopin0").val('');
        $("#myInfopin1").val('');
        vm.pinValidation = false;
        vm.pinStatus = '';
        $scope.pinForm.$setUntouched();
    };

    vm.hidepin = function() {
        vm.mypin = false;
    };

    vm.showaddress = function() {
        vm.myaddress = true;
        $scope.addressForm.$setUntouched();
    };

    vm.hideaddress = function() {
        vm.myaddress = false;
        vm.user_address.address = '';
        vm.user_address.formatted_address = '';
        $scope.addressForm.$setPristine();
        vm.addressValidation = false;
    };

    vm.showSubsciption = function(product) {
        vm.currentSubscription.id = product;
        vm.currentSubscription.showForm = true;
    }

    vm.updateProfile = function() {
        StoreService.get('api/v1/profile', vm.userId).then(function(results) {

        });

    }

    vm.updatePhone = function(valid) {
        if (valid) {
            vm.phoneValidation = false;
            vm.phoneValidationMsg = null;
            vm.phoneStatus = null;
            vm.loaderShow = true;

            StoreService.update('api/v1/user/update_phone', vm.userId, vm.phone).then(function(result) {
                if (result.status == 422) {
                    vm.phoneValidation = true;
                    vm.phoneValidationMsg = result.data;
                    vm.phoneStatus = null;
                } else if (result.error) {
                    vm.phoneValidation = true;
                    vm.phoneStatus = result.response.message;
                    vm.phoneValidationMsg = null;
                } else {
                    if (result.response.is_dirty) {
                        //window.location.href = '/dashboard';
                        vm.waitingForConfirmation = true;
                    }
                    vm.userPhone = vm.phone.phone;
                    vm.phoneValidation = false;
                    //vm.pinStatus = result.response;
                    vm.phoneStatus = null;
                    vm.hidephone();
                }
                vm.loaderShow = false;
            }, function(error) {
                console.log(error);
            });
        }
    }

    vm.revertPhone = function() {
        vm.phoneStatus = null;
        vm.loaderShow = true;
        StoreService.save('api/v1/user/revert_phone/' + vm.userId).then(function(result) {
            if (result.error) {
                vm.phoneStatus = result.response.message;
            } else {
                vm.waitingForConfirmation = false;
                vm.userPhone = result.response.phone;
                vm.phoneStatus = null;
                vm.hidephone();
            }
            vm.loaderShow = false;
        }, function(error) {
            console.log(error);
        });
    }

    vm.updatePassword = function(valid) {
        if (valid) {
            vm.passwordValidation = false;
            vm.passwordValidationMsg = null;
            vm.passwordStatus = null;
            vm.loaderShow = true;
            StoreService.update('api/v1/user/update_password', vm.userId, vm.password).then(function(result) {
                if (result.status == 422) {
                    console.log(result.data);
                    vm.passwordValidation = true;
                    vm.passwordValidationMsg = result.data;
                    vm.passwordStatus = null;
                } else if (result.error) {
                    vm.passwordValidation = true;
                    vm.passwordStatus = result.response.message;
                    vm.passwordValidationMsg = null;
                } else {
                    vm.passwordValidation = false;
                    //vm.passwordStatus = result.response;
                    vm.passwordStatus = null;
                    vm.password.old_password = null;
                    vm.password.password = null;
                    vm.hidepassword();
                }
                vm.loaderShow = false;
            }, function(error) {
                console.log(error);
            });
        }
    }

    vm.updatePin = function(valid) {
        if (valid) {
            vm.pinValidation = false;
            vm.pinValidationMsg = null;
            vm.pinStatus = null;
            vm.loaderShow = true;

            StoreService.update('api/v1/user/update_pin', vm.userId, vm.pin).then(function(result) {
                if (result.status == 422) {
                    vm.pinValidation = true;
                    vm.pinValidationMsg = result.data;
                    vm.pinStatus = null;
                } else if (result.error) {
                    vm.pinValidation = true;
                    vm.pinStatus = result.response.message;
                    vm.pinValidationMsg = null;
                } else {
                    vm.pinValidation = false;
                    //vm.pinStatus = result.response;
                    vm.pinStatus = null;
                    vm.hidepin();
                }
                vm.loaderShow = false;
            }, function(error) {
                console.log(error);
            });
        }
    }

    vm.updateAddress = function(valid) {
        vm.user_address.streetnumber      = $('#street_number').val();
        vm.user_address.street            = $('#route').val();
        vm.user_address.city              = $('#locality').val();
        vm.user_address.state             = $('#administrative_area_level_1').val();
        vm.user_address.zipcode           = $('#postal_code').val();
        vm.user_address.country           = $('#country').val();
        vm.user_address.latitude          = $('#latitude').val();
        vm.user_address.longitude         = $('#longitude').val();
        vm.user_address.formatted_address = $('#formatted_address').val();

        if($('#formatted_address').val() != '') {
            vm.userFormattedAddress = $('#formatted_address').val();
        }
        vm.user_address.timezone_name     = $('#timezone_name').val();
        vm.user_address.timezone_id       = $('#timezone_id').val();

        if (valid) {

            vm.addressValidation    = false;
            vm.addressValidationMsg = null;
            vm.addressStatus        = null;
            vm.loaderShow           = true;

            StoreService.update('api/v1/user/update_address', vm.userId, vm.user_address).then(function(result) {
                if (result.status == 422) {
                    vm.addressValidation = true;
                    vm.addressValidationMsg = result.data;
                    vm.addressStatus = null;
                } else if (result.error) {
                    vm.addressValidation = true;
                    vm.addressStatus = result.response.message;
                    vm.addressValidationMsg = null;
                } else {
                    vm.addressValidation = false;
                    //vm.addressStatus = result.response;
                    vm.addressStatus = null;
                    vm.user_address.latitude = $('#latitude').val();
                    vm.user_address.longitude = $('#longitude').val();

                    vm.hideaddress();
                }
                vm.loaderShow = false;
            }, function(error) {
                console.log(error);
            });
        }
    }

    vm.updateName = function(name) {
        console.log($rootScope.userName);
        if (name != '') {
            data = {
                'name': name
            };

            StoreService.update('api/v1/user/update_name', vm.userId, data).then(function(result) {
                if (result.status == 422) {
                    vm.nameValidation = true;
                    vm.nameValidationMsg = result.data;
                    vm.nameStatus = null;
                } else if (result.error) {
                    vm.nameValidation = true;
                    vm.nameStatus = result.response.message;
                    vm.nameValidationMsg = null;
                } else {
                    $rootScope.userName = name;
                    console.log($rootScope.userName);
                    vm.nameValidation = false;
                    vm.nameStatus = null;
                    vm.userName = vm.profile_data.name;
                }
            }, function(error) {
                console.log(error);
            });
        }
    }

    socket.on("user_account_verify_" + $('#auth_id').val() + ":App\\Events\\User\\AccountVerified", function(message) {
        if( !message.user.phone2 && message.user.phone2_verified ) {
            vm.waitingForConfirmation = false;
        }
    });
}]);
