angular.module('app.core').controller('EmergencyController', ['ShowService', 'StoreService', '$scope', 'socket', '$rootScope', function(ShowService, StoreService, $scope, socket, $rootScope) {

    var vm = this;

    vm.userId = null;
    vm.e911 = Lois.e911;

    vm.contactValidation = false;
    vm.contactValidationMsg = null;
    vm.contactStatus = null;

    vm.editContactValidation = false;
    vm.editContactValidationMsg = null;
    vm.editContactStatus = null;

    vm.showaddnewcontact = function() {
        vm.myaddnewcontact = true;
        vm.mycontactedit = true;
        vm.mycontactdelete = true;
        $scope.emergencyForm.$setUntouched();
    };

    vm.hideaddnewcontact = function() {
        vm.myaddnewcontact = false;
        vm.mycontactedit = false;
        vm.mycontactdelete = false;

        vm.contact.name = '';
        vm.contact.phone = '';
    };

    vm.loadContacts = function() {
        ShowService.query('api/v1/contacts').then(function(result) {
            var contacts = result.response;
            if ( window.Lois.env == 'local' ) {
                contacts.push({name: 'E911', phone: Lois.e911});
            }
            vm.contacts = contacts;
        });
    }

    vm.addContact = function(valid) {
        if (valid) {
            vm.loaderShow = true;
            StoreService.save('api/v1/contacts', vm.contact).then(function(result) {
                if (result.status == 422) {
                    vm.contactValidation = true;
                    vm.contactValidationMsg = result.data;
                    vm.contactStatus = null;
                } else if (result.error) {
                    vm.contactValidation = true;
                    vm.contactStatus = result.response.message;
                    vm.contactValidationMsg = null;
                } else {
                    vm.contactValidation = false;
                    vm.contactStatus = result.response.message;

                    var new_contact = {
                        name: vm.contact.name,
                        phone: vm.contact.phone,
                        id: result.response.id,
                        is_confirmed: 0,
                    };
                    vm.contact.name = null;
                    vm.contact.phone = null;

                    vm.contacts.splice(vm.contacts.length-1, 0, new_contact);
                    vm.hideaddnewcontact();
                }
                vm.loaderShow = false;
            }, function(error) {
                console.log(error);
            });
        }
    }

    vm.editContact = function(contact) {
        vm.mycontact = contact;
        vm.mycontact.showForm = true;
    }

    vm.cancelContact = function(contact, index) {
        vm.mycontact = contact;
        vm.editcontact[index].name = contact.name
        vm.mycontact.showForm = false;
    }

    vm.updateContact = function(contact, index, id, valid) {
        if (valid) {
            data = {
                'name': vm.editcontact[index].name
            };
            StoreService.update('api/v1/contacts', contact.id, data).then(function(result) {
                vm.editContactValidation = false;
                vm.editContactValidationMsg = null;
                vm.editContactStatus = null;

                if (result.status == 422) {
                    vm.editContactValidation = true;
                    vm.editContactValidationMsg = result.data;
                    vm.editContactStatus = null;
                } else if (result.error) {
                    vm.editContactValidation = true;
                    vm.editContactStatus = result.response.message;
                    vm.editContactValidationMsg = null;
                } else {
                    vm.editContactStatus = result.response.message;

                    vm.mycontact = contact;
                    vm.mycontact.showForm = false;

                    vm.contactValidation = false;
                    vm.contactStatus = null;

                    vm.loadContacts();
                }
            }, function(error) {
                console.log(error);
            });

        }

    }

    vm.deleteContact = function(contact) {
        ShowService.remove('api/v1/contacts', contact.id).then(function(result) {
            var index = vm.contacts.indexOf(contact);
            vm.contacts.splice(index, 1);
            $("#deleteModal" + contact.id).modal('hide');

            vm.contactValidation = false;
            vm.contactStatus = null;
        });
    }

    socket.on("user_contact_verify_" + $('#auth_id').val() + ":App\\Events\\User\\ContactVerified", function(message){
        if( message.contact.is_confirmed == 1 ) {
            $('#statusContainer' + message.contact.id).html('<div class="alert alert-success alert-dismissable alert-confirm subscription-success" style="padding:3px 10px 3px 1px;" title="Confirmed">Confirmed <i class="fa fa-check-circle fa-lg" title="Confirmed"></i></div>');
        }
        else {
            $('#statusContainer' + message.contact.id).html('<div class="alert alert-danger alert-dismissable alert-warning" style="padding:3px 10px 3px 9px;" title="Rejected">Rejected <i class="fa fa-ban fa-lg" title="Rejected"></i></div>');
        }
        vm.contactValidation = false;
        vm.contactStatus = null;
    });
}]);
