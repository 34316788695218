/**
 * Auth controller is for registe and login.
 */
angular.module('app.core').controller('AuthController', ['ShowService', 'StoreService', '$scope', '$rootScope', function(ShowService, StoreService, $scope, $rootScope) {
    var vm = this;
    vm.serverValidation = false;
    vm.serverValidationMsg = null;

    vm.loginValidation = false;
    vm.loginValidationMesg = null;

    vm.passwordValidation = false;
    vm.passwordValidationMsg = null;
    vm.passwordStatus = null;
    vm.emailSent = false;

    vm.resetValidation = false;
    vm.resetValidationMsg = null;
    vm.resetStatus = null;
    vm.passwordChanged = false;

    vm.signUp = function(valid) {
        if (valid) {
            vm.register.streetnumber      = $('#street_number').val();
            vm.register.street            = $('#route').val();
            vm.register.city              = $('#locality').val();
            vm.register.state             = $('#administrative_area_level_1').val();
            vm.register.zipcode           = $('#postal_code').val();
            vm.register.country           = $('#country').val();
            vm.register.latitude          = $('#latitude').val();
            vm.register.longitude         = $('#longitude').val();
            vm.register.formatted_address = $('#formatted_address').val();
            vm.register.timezone_name     = $('#timezone_name').val();
            vm.register.timezone_id       = $('#timezone_id').val();

            vm.serverValidation           = false;
            vm.serverValidationMsg        = null;
            vm.loaderShow                 = true;
            /*angular.element("#signup-modal")[0].scrollTop = 0;*/

            StoreService.save('api/v1/auth/register', vm.register).then(function(result) {
                if (result.error == false) {
                    window.location.href = '/dashboard';
                } else {
                    vm.serverValidation = true;
                    console.log(result.response);
                    vm.serverValidationMsg = result.response.message;
                    vm.loaderShow = false;

                }
            }, function(error) {
                console.log(error);
            });
        }

    }

    vm.signIn = function() {
        vm.loaderShow = true;
        vm.loginValidation = false;
        vm.loginValidationMesg = null;
        StoreService.save('api/v1/auth/login', vm.login).then(function(result) {
            if (result.error == false) {
                window.location.href = '/dashboard';
            } else {
                vm.loginValidation = true;
                vm.loginValidationMesg = result.response.message;
                vm.loaderShow = false;
            }
        }, function(error) {
            console.log(error);
        });
    }

    vm.sendPasswordResetLink = function() {
        vm.loaderShow = true;
        vm.passwordStatus = null;
        vm.passwordValidation = false;
        StoreService.save('api/v1/password/email', vm.password).then(function(result) {
            vm.loaderShow = false;
            if (result.error == false) {
                if (result.status) {
                    vm.passwordStatus = result.status;
                    vm.emailSent = true;
                }
            } else {
                vm.passwordValidation = true;
                if (result.status) {
                    vm.passwordValidationMsg = null;
                    vm.passwordStatus = result.status;
                } else {
                    vm.passwordStatus = null
                    vm.passwordValidationMsg = result.response.message;

                }
            }
        }, function(error) {
            console.log(error);
        });
    }

    vm.resetPassword = function() {
        vm.reset.token = $('#token').val();
        vm.loaderShow = true;
        vm.resetStatus = null;
        vm.resetValidation = false;
        StoreService.save('api/v1/password/reset', vm.reset).then(function(result) {
            vm.loaderShow = false;
            if (result.error == false) {
                if (result.status) {
                    vm.resetStatus = result.status;
                    vm.passwordChanged = true;
                    setTimeout(function() {
                        window.location.href = '/dashboard';
                    }, 2000);
                }
            } else {
                vm.resetValidation = true;
                if (result.status) {
                    vm.resetValidationMsg = null;
                    vm.resetStatus = result.status;
                } else {
                    vm.resetStatus = null
                    vm.resetValidationMsg = result.response.message;

                }
            }
        }, function(error) {
            console.log(error);
        });
    }

    $scope.$on('loginModalShow', function (event, data) {
        vm.loginValidation     = false;
        vm.loginValidationMesg = null;
        vm.login               = null;
        $('#loginEmail').val('');
        $('#loginPassword').val('');
        $scope.loginForm.$setPristine();
        $scope.loginForm.$setUntouched();
    });

    $scope.$on('registerModalClosed', function (event, data) {
        vm.serverValidation    = false;
        vm.serverValidationMsg = null;
        vm.register            = null;
        $scope.fromRegister.$setPristine();
        $scope.fromRegister.$setUntouched();
    });

    $scope.$on('registerModalShow', function (event, data) {
        $scope.fromRegister.$setUntouched();
    });

    $scope.$on('passwordModalShow', function (event, data) {
        $('#passwordEmail').val('');
        vm.password = null;
        $scope.passwordForm.$setPristine();
        $scope.passwordForm.$setUntouched();
    });

    $scope.$on('passwordModalClosed', function (event, data) {
        vm.emailSent = false;
        vm.passwordStatus = null;
        vm.passwordValidation = false;
    });
}]);
