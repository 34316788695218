angular.module('app.core').controller('CheckinController', ['$scope', '$filter', '$location', 'ShowService', 'StoreService', '$window', '$timeout', '$state', function($scope, $filter, $location, ShowService, StoreService, $window, $timeout, $state) {

    var vm                  = this;

    $scope.result1          = '';
    $scope.options1         = null;
    $scope.details1         = '';

    vm.checkinValidation    = false;
    vm.checkinValidationMsg = null;
    vm.pastDateTimeError    = false;

    $scope.btnValue         = 'who_you_are_meeting';
    $scope.toggle           = true;
    vm.wizardMeeting        = true;
    vm.wizardNext           = true;

    vm.changeAddressBtn     = false;
    vm.validateAddressBtn   = false;
    vm.skipNonE911Btn       = false;

    vm.validAddress = false;
    vm.validAddressMessage = null;
    vm.showValidAddresMessage = false;

    vm.addressVerified = false;

    var twoMinAfter = moment().add(2, 'minutes').toDate();
    $scope.h = $filter('date')(twoMinAfter, 'h');
    $scope.m = $filter('date')(twoMinAfter, 'm');
    $scope.a = $filter('date')(twoMinAfter, 'a');
    $scope.date = $filter('date')(twoMinAfter, 'MMMM d, y');

    vm.contacts = null;

    vm.loadContacts = function() {
        ShowService.query('api/v1/contacts/verified').then(function(result) {
            //vm.contacts = result.response;
            var contacts = result.response;
            if ( window.Lois.env == 'local' ) {
                contacts.push({name: 'E911', phone: Lois.e911, id:'E911'});
            }
            vm.contacts = contacts;
        }, function(error) {
            console.log(error);
        });
    }

    vm.togglePeriod = function() {
        if ($scope.a === 'AM') {
            $scope.a = 'PM'
        } else {
            $scope.a = 'AM';
        }
    }

    vm.prevClick = function() {
        $scope.btnValue = "who_you_are_meeting";
        if (vm.wizardContactYou) {
            $scope.btnValue = "meeting_place";
        }
        if (vm.wizardSave) {
            $scope.btnValue = "contact_you";
        }
    }
    vm.nextClick = function() {
        $scope.btnValue = "meeting_place";
        if (vm.wizardPlace) {
            $scope.btnValue = "contact_you";
        }
        if (vm.wizardContactYou) {
            $scope.btnValue = "be_saved";
        }
        $scope.ddMMyyyy = $filter('date')(new Date(), 'dd/MM/yyyy');
        $scope.ddMMMMyyyy = $filter('date')(new Date(), 'dd, MMMM yyyy');
        $scope.HHmmss = $filter('date')(new Date(), 'HH:mm:ss');
        $scope.hhmmsstt = $filter('date')(new Date(), 'hh:mm:ss a');
    }

    $scope.$watch(
        function($scope) {
            return ($scope.btnValue);
        },
        function(newValue) {
            if (newValue == "meeting_place") {
                vm.wizardprev = true;
                vm.wizardPlace = true;
                vm.wizardMeeting = false;
                vm.wizardContactYou = false;
                vm.currentForm = 'locationForm';

                if (vm.changeAddressBtn) {
                    vm.validateAddressBtn = true;
                    vm.skipNonE911Btn = true;

                    vm.mysavebutton = false;
                }
            } else if (newValue == "who_you_are_meeting") {
                vm.wizardprev = false;
                vm.wizardMeeting = true;
                vm.wizardPlace = false;
                vm.currentForm = 'meetingForm';

                vm.validateAddressBtn = false;
                if (vm.changeAddressBtn) {
                    vm.skipNonE911Btn = true;

                    vm.mysavebutton = false;
                }
            } else if (newValue == "contact_you") {
                vm.wizardContactYou = true;
                vm.wizardPlace = false;
                vm.wizardSave = false;
                vm.wizardNext = true;
                vm.currentForm = 'timeForm';

                vm.validateAddressBtn = false;
                vm.skipNonE911Btn = false;

                vm.showsavebutton(vm.addNew.user_contact_id);
            } else if (newValue == "be_saved") {
                vm.wizardContactYou = false;
                vm.wizardSave = true;
                vm.wizardNext = false;
                vm.currentForm = 'alarmTypeForm';

                vm.wizardprev = true;
                vm.validateAddressBtn = false;
                vm.skipNonE911Btn = false;
                vm.showsavebutton(vm.addNew.user_contact_id);
            }
        }
    );

    vm.showsavebutton = function(contact) {
        if (contact) {
            vm.mysavebutton = true;
        } else {
            vm.mysavebutton = false;
        }
    };

    vm.toggleAlertClass = function(e) {
        angular.element(e.target).closest('div').siblings().removeClass('active-alert');
        angular.element(e.target).closest('div').addClass('active-alert');
    }

    vm.isThisDisabled = function() {
        if ($scope.btnValue === 'who_you_are_meeting') {
            if ($scope.meetingForm.$valid)
                return false;
        } else if ($scope.btnValue === 'meeting_place') {
            //if ($scope.locationForm.$valid)
            if ($scope.locationForm.$valid && $('#formatted_address').val() != '')
                return false;
        } else if ($scope.btnValue === 'contact_you') {
            if ($scope.timeForm.$valid) {
                var before = vm.isPostCheckInTime();
                vm.pastDateTimeError = before;
                return before;
            }
        } else if ($scope.btnValue === 'be_saved') {
            if ($scope.alarmTypeForm.$valid)
                return false;
        }
        return true;
    }

    vm.addCheckin = function(checkin) {

        checkin.streetnumber      = $('#street_number').val();
        checkin.street            = $('#route').val();
        checkin.city              = $('#locality').val();
        checkin.state             = $('#administrative_area_level_1').val();
        checkin.zipcode           = $('#postal_code').val();
        checkin.country           = $('#country').val();
        checkin.latitude          = $('#latitude').val();
        checkin.longitude         = $('#longitude').val();
        checkin.formatted_address = $('#formatted_address').val();
        checkin.timezone_name     = $('#timezone_name').val();
        checkin.timezone_id       = $('#timezone_id').val();
        checkin.time.meridian     = $('#meridian').val();

        checkin.address_verified  = vm.addressVerified;

        vm.serverValidation       = false;
        vm.serverValidationMsg    = null;

        angular.element(document.getElementById('wizardSaveAlert'))[0].disabled = true;
        var text = $('#wizardSaveAlert').find('span').text();
        $('#wizardSaveAlert').find('span').text('Please wait...');

        StoreService.save('api/v1/checkin', checkin).then(function(result) {
            if (result.status == 422) {

                $scope.btnValue      = 'be_saved';

                vm.wizardNext        = false;
                vm.wizardprev        = true;
                vm.wizardMeeting     = false;
                vm.wizardPlace       = false;
                vm.wizardContactYou  = false;
                vm.wizardSave        = true;
                vm.currentForm       = 'alarmTypeForm';

                vm.checkinValidation = true;

                if ( result.data.formatted_address ) {
                    vm.changeAddressBtn = true;
                }

                vm.checkinValidationMsg = result.data;
                $window.scrollTo(0, angular.element('#checkinErrorContainer').offsetTop);
            }
            if (result.error == false) {
                $state.go('alarm');
                //window.location.href = '/dashboard#alarm';
            }

            angular.element(document.getElementById('wizardSaveAlert'))[0].disabled = false;
            $('#wizardSaveAlert').find('span').text(text);
        }, function(error) {
            console.log(error);
        });
    }

    vm.isPostCheckInTime = function() {
        // Date/Time inputs
        var input = vm.addNew.date + ' ' + vm.addNew.time['hour'] + ':' + vm.addNew.time['minute'] + ' ' + $scope.a;
        var fmt   = "MMMM D, YYYY h:m A";  // must match the input
        var zone  = $('#timezone_id').val();

        // construct a moment object
        var m = moment.tz(input, fmt, zone);

        // convert it to utc
        m.utc();

        // format it for output
        var s = m.format(fmt);
        //..OneLiner
        //var s = moment.tz(input, fmt, zone).utc().format(fmt);

        return before = moment(s).isBefore(moment().utc().format(fmt));
    }

    vm.changeAddress = function() {
        $scope.btnValue     = "meeting_place";
        vm.wizardNext       = true;
        vm.wizardPlace      = true;
        vm.wizardMeeting    = false;
        vm.wizardContactYou = false;
        vm.wizardSave       = false;
        vm.currentForm      = 'locationForm';

        vm.validateAddressBtn = true;
        vm.mysavebutton       = false;
        vm.skipNonE911Btn     = true;
    }

    vm.skipNonE911 = function() {
        $scope.btnValue      = 'be_saved';
        vm.wizardMeeting     = false;
        vm.wizardPlace       = false;
        vm.wizardContactYou  = false;
        vm.wizardSave        = true;
        vm.checkinValidation = true;
        vm.currentForm       = 'alarmTypeForm';

        vm.skipNonE911Btn     = false;
        vm.validateAddressBtn = false;
        vm.mysavebutton       = true;
        vm.changeAddressBtn   = true;
    }

    vm.validateAddress = function($event) {
        address = {};
        address.addresstype       = $('#addresstype').val();
        address.addresstypenumber = $('#addresstypenumber').val();
        address.streetnumber      = $('#street_number').val();
        address.street            = $('#route').val();
        address.city              = $('#locality').val();
        address.state             = $('#administrative_area_level_1').val();
        address.zipcode           = $('#postal_code').val();
        address.country           = $('#country').val();

        angular.element(document.getElementById('wizardValidateAddress'))[0].disabled = true;
        var text = $('#wizardValidateAddress').find('span').text();
        $('#wizardValidateAddress').find('span').text('Validating E911 Address...');

        StoreService.save('api/v1/checkin/address_valid', address).then(function(result) {
            if ( result.success ) {
                vm.validAddress = true;
                vm.validAddressMessage = 'The check-in address is a valid E911 address.';
                vm.showValidAddresMessage = true;

                if (vm.checkinValidationMsg.formatted_address) {
                    vm.checkinValidationMsg.formatted_address.splice(0, 1);
                    delete vm.checkinValidationMsg.formatted_address;
                    if (!Object.keys(vm.checkinValidationMsg).length) {
                        vm.checkinValidation = false;
                    }
                    vm.changeAddressBtn = false;
                }
            }
            else {
                vm.validAddress = false;
                vm.validAddressMessage = 'The check-in address is not a valid E911 address.';
                vm.showValidAddresMessage = true;
            }

            vm.addressVerified = result.success;

            angular.element(document.getElementById('wizardValidateAddress'))[0].disabled = false;
            $('#wizardValidateAddress').find('span').text(text);
        });
    }

    vm.checkinActionBtnClass = function(btn) {
        if ( btn == 'save' ) {
            if ( vm.validateAddressBtn &&  vm.skipNonE911Btn ) {
                return 'col-sm-4 col-md-4'
            }
        }

        if ( btn == 'validate' ) {
            if ( vm.skipNonE911Btn ) {
                return 'col-sm-6 col-md-6'
            }
        }

        if ( btn == 'skip' ) {
            if ( vm.validateAddressBtn ) {
                return 'col-sm-6 col-md-6'
            }
        }
    }

    vm.disableValidateBtn = function() {
        if ( $('#formatted_address').val() != '' && $scope.locationForm.$valid ) {
            return false;
        }

        return true;
    }

    vm.onChangeAddress = function() {
        $timeout(function() {
            document.getElementById('timezone_id').value = null;
            document.getElementById('timezone_name').value = null;
            document.getElementById('formatted_address').value = null;

            vm.addressVerified = false;
            vm.showValidAddresMessage = false;
        });
    }

}]);
