angular.module('app.core').controller('HistoryController', ['$scope', '$filter', '$location', 'ShowService', 'StoreService', '$window', function($scope, $filter, $location, ShowService, StoreService, $window) {

	var vm      = this;
	vm.checkins = null;

	vm.resetFilter = function() {
	    vm.search = {
	  		'$': '',
	  		'check_in_status':'',
	  	};
	  	vm.date       = {startDate: null, endDate: null};
	  	vm.dateFilter = {startDate: '2016-01-01', endDate: moment()};
    };

	vm.date     = {startDate: null, endDate: null};
	vm.dateFilter = {startDate: '2016-01-01', endDate: moment()};

	vm.search = vm.resetFilter();

	vm.dateRangeOptions = {
		opens: "center",
		eventHandlers: {
            'apply.daterangepicker': function (ev, picker) {
                vm.dateFilter = vm.date;
            },
            'cancel.daterangepicker': function (ev, picker) {
                vm.date       = {startDate: null, endDate: null};
				vm.dateFilter = {startDate: '2016-01-01', endDate: moment()};
            }
        },
	};

 	$scope.currentPage = 1;
  	$scope.pageSize = 10;

    vm.loadHistory = function() {
        ShowService.query('api/v1/history').then(function(result) {
            vm.checkins = result;
        });
    };

    $scope.dateRangeFilter = function (property, startDate, endDate) {
	    return function (item) {
	        if (item[property] === null) return false;

	        var itemDate = moment(item[property]);
	        var s = moment(startDate, "DD-MM-YYYY");
	        var e = moment(endDate, "DD-MM-YYYY");

	        if (itemDate >= s && itemDate <= e) return true;
	        return false;
	    }
	};
}]);